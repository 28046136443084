import React from 'react';

const Maintenance = () => {
  return (
      <div className="max-w-[50rem] flex flex-col mx-auto size-full">
        <header className="mb-auto flex justify-center z-50 w-full py-4">
          <nav className="px-4 sm:px-6 lg:px-8">
            <a className="flex-none text-xl font-semibold sm:text-3xl dark:text-white" href="#" aria-label="Brand">Brand</a>
          </nav>
        </header>
        <main id="content">
          <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
            <h1 className="block text-7xl font-bold text-gray-800 sm:text-9xl dark:text-white">Maintenance</h1>
            <p className="mt-3 text-gray-600 dark:text-neutral-400">Le site est actuellement en maintenance</p>
          </div>
        </main>
        <footer className="mt-auto text-center py-5">
          <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
            <p className="text-sm text-gray-500 dark:text-neutral-500">© All Rights Reserved. 2022.</p>
          </div>
        </footer>
      </div>
  );
};

export default Maintenance;
